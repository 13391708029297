import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/politica-privacidade-aplicativo',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy-app" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/politica-privacidade-pmvv',
    name: 'PrivacyPolicyPMVV',
    component: () => import(/* webpackChunkName: "privacy-policy-pmvv" */ '../views/PrivacyPolicyPMVV.vue'),
  },
  {
    path: '/politica-privacidade-cariacica',
    name: 'PrivacyPolicyCariacica',
    component: () => import(/* webpackChunkName: "privacy-policy-cariacica" */ '../views/PrivacyPolicyCariacica.vue'),
  },
  {
    path: '/politica-privacidade-cidade',
    name: 'PrivacyPolicyCity',
    component: () => import(/* webpackChunkName: "privacy-policy-city" */ '../views/PrivacyPolicyCity.vue'),
  },
  {
    path: '/politica-privacidade-itapemirim',
    name: 'PrivacyPolicyItapemirim',
    component: () => import(/* webpackChunkName: "privacy-policy-itapemirim" */ '../views/PrivacyPolicyItapemirim.vue'),
  },
  {
    path: '/politica-privacidade-serra',
    name: 'PrivacyPolicySerra',
    component: () => import(/* webpackChunkName: "privacy-policy-itapemirim" */ '../views/PrivacyPolicySerra.vue'),
  },
  {
    path: '/politica-privacidade-cachoeiro-itapemirim',
    name: 'PrivacyPolicyItapemirim',
    component: () => import(/* webpackChunkName: "privacy-policy-itapemirim" */ '../views/PrivacyPolicyCachoeiroItapemirim.vue'),
  },
  {
    path: '/politica-privacidade-vitoria',
    name: 'PrivacyPolicyVitoria',
    component: () => import(/* webpackChunkName: "privacy-policy-vitoria" */ '../views/PrivacyPolicyVitoria.vue'),
  },
  {
    path: '/politica-privacidade-aracruz',
    name: 'PrivacyPolicyAracruz',
    component: () => import(/* webpackChunkName: "privacy-policy-aracruz" */ '../views/PrivacyPolicyAracruz.vue'),
  },
  {
    path: '/politica-privacidade-daviesmael',
    name: 'PrivacyPolicyEsmael',
    component: () => import(/* webpackChunkName: "privacy-policy-esmael" */ '../views/PrivacyPolicyEsmael.vue'),
  },
  {
    path: '/politica-privacidade-procon-vitoria',
    name: 'PrivacyPolicyProconVitoria',
    component: () => import(/* webpackChunkName: "privacy-policy-proconvitoria" */ '../views/PrivacyPolicyProconVitoria.vue'),
  },
  {
    path: '/politica-privacidade-oabes',
    name: 'PrivacyPolicyOABES',
    component: () => import(/* webpackChunkName: "privacy-policy-proconvitoria" */ '../views/PrivacyPolicyOABES.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
